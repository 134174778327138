import { SemanticICONS } from 'semantic-ui-react';

import { ITEM_TYPES } from '~/types/notifications.types';
import { ACCESS_COMPANY, ACCESS_GROUP, AccessPermission } from '~/types/access.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { ESTATE_BASE_FOREIGN } from '~/types/estate.types';

import estateStore from '~/stores/estateStore';
import requestStore from '~/stores/requestStore';
import wishStore from '~/stores/wishStore';
import showingStore from '~/stores/showingStore';
import taskStore from '~/stores/taskStore';
import contactStore from '~/stores/contactStore';
import cellCallStore from '~/stores/cell/cellCallStore';
import callEventStore from '~/stores/cell/callEventStore';
import userStore from '~/stores/userStore';
import dealStore from '~/stores/dealStore';
import statisticStore from '~/stores/statisticStore';
import exportStore from '~/stores/export/exportStore';
import learningStore from '~/stores/learningStore';
import settingStore from '~/stores/settingStore';
import { ChatStore } from '~/stores/chatStore';

export type MenuItemSubmenuType = {
    icon?: SemanticICONS;
    title: string;
    src: string;
    createPath?: string;
    access?: ITEM_TYPES;
    accessPermission?: AccessPermission;
    badgeCount?: number;
};

export type MenuItemType = MenuItemSubmenuType & {
    submenu?: MenuItemSubmenuType[];
};

const requestSubmenu: MenuItemSubmenuType[] = [
    { title: 'Список заявок', src: '/requests/list', access: requestStore.moduleName },
    { title: 'Рекрутинг', src: '/requests/hh', access: requestStore.moduleName, accessPermission: ACCESS_GROUP }
];

if (['crm.agentbase.ru', 'localhost:3000'].includes(document.location.host)) {
    requestSubmenu.push({ title: 'Фиксация цен', src: '/spp/fixPrices', access: requestStore.moduleName });
}

export const MenuTree = (chatStore: ChatStore): MenuItemType[] => [
    {
        icon: 'building',
        title: 'Недвижимость',
        src: '/estate',
        access: estateStore.moduleName,
        submenu: [
            {
                title: 'Продажа / Аренда',
                createPath: `/estate/crm/sell/${CREATING_ITEM_ID}`,
                src: '/estate/crm',
                access: estateStore.moduleName
            },
            // {
            //     title: 'Новостройки',
            //     src: `/complex/crm`,
            //     access: estateStore.moduleName
            // },
            {
                title: 'Зарубежная',
                src: `/estate/${ESTATE_BASE_FOREIGN}`,
                access: estateStore.moduleName
            },
            {
                title: 'Покупатели',
                createPath: `/wishes/list/overview/${CREATING_ITEM_ID}`,
                src: '/wishes/list',
                access: wishStore.moduleName
            },
            {
                title: 'Показы',
                createPath: `/showings/list/overview/${CREATING_ITEM_ID}`,
                src: '/showings/list',
                access: showingStore.moduleName
            }
        ]
    },
    {
        icon: 'tasks',
        title: 'Задачи',
        src: '/tasks/list',
        access: taskStore.moduleName,
        submenu: [
            {
                title: 'Список задач',
                createPath: `/tasks/list/overview/${CREATING_ITEM_ID}`,
                src: '/tasks/list',
                access: taskStore.moduleName
            }
        ]
    },
    {
        icon: 'address card',
        title: 'Контакты',
        src: '/contacts',
        access: contactStore.moduleName,
        submenu: [
            {
                title: 'Список контактов',
                createPath: `/contacts/list/profile/${CREATING_ITEM_ID}`,
                src: '/contacts/list',
                access: contactStore.moduleName
            },
            {
                title: 'Канбан доски',
                createPath: `/contacts/list/profile/${CREATING_ITEM_ID}`,
                src: '/contacts/kanban',
                access: contactStore.moduleName
            }
        ]
    },
    {
        icon: 'phone',
        title: 'Звонки',
        src: '/calls/list',
        access: cellCallStore.moduleName,
        submenu: [
            { title: 'Список звонков', src: '/calls/list', access: cellCallStore.moduleName },
            { title: 'Запланированные звонки', src: '/calls/events', access: callEventStore.moduleName }
        ]
    },
    {
        icon: 'chat',
        title: 'Чаты',
        src: '/chat',
        access: userStore.moduleName,
        submenu: [
            {
                title: 'Чаты с рекламы',
                src: '/chat/avito',
                access: userStore.moduleName,
                badgeCount: null
            },
            {
                title: 'Внутренние / Whatsapp',
                src: '/chat/users',
                access: userStore.moduleName,
                badgeCount: chatStore.notReadMessagesList.length || null
            }
        ]
    },
    {
        icon: 'money',
        title: 'Договоры',
        src: '/deals',
        access: dealStore.moduleName,
        submenu: [
            {
                title: 'Сделки (Продажа / Покупка)',
                createPath: `/deals/sell/overview/${CREATING_ITEM_ID}`,
                src: '/deals/sell',
                access: dealStore.moduleName
            },
            { title: 'Эксклюзивы', src: '/deals/exclusive', access: dealStore.moduleName },
            { title: 'Аренда', src: '/deals/rent', access: dealStore.moduleName },
            { title: 'Сопровождение', src: '/deals/accompaniment', access: dealStore.moduleName },
            { title: 'Новостройка', src: '/deals/newbuilding', access: dealStore.moduleName },
            { title: 'Ипотека', src: '/deals/mortgage', access: dealStore.moduleName }
        ]
    },
    {
        icon: 'wordpress forms',
        title: 'Заявки',
        src: '/requests',
        access: requestStore.moduleName,
        submenu: requestSubmenu
    },
    {
        icon: 'chart pie',
        title: 'Аналитика',
        src: '/analytics',
        access: statisticStore.moduleName,
        accessPermission: ACCESS_GROUP,
        submenu: [
            { title: 'Таблицы', src: '/analytics', access: statisticStore.moduleName },
            { title: 'KPI', src: '/kpi', access: statisticStore.moduleName, accessPermission: ACCESS_COMPANY },
            {
                title: 'Реклама',
                src: '/ads/stat',
                accessPermission: ACCESS_GROUP,
                access: exportStore.moduleName
            }
        ]
    },

    {
        icon: 'graduation',
        title: 'Обучение',
        src: '/learning',
        access: learningStore.moduleName,
        submenu: [{ title: 'Материалы', src: '/learning', access: learningStore.moduleName }]
    },
    {
        icon: 'users',
        title: 'Сотрудники',
        src: '/users',
        access: userStore.moduleName,
        submenu: [
            {
                title: 'Список сотрудников',
                createPath: `/users/list/profile/${CREATING_ITEM_ID}`,
                src: '/users/list',
                access: userStore.moduleName
            },
            { title: 'Офисы / Отделы', src: '/users/groups', access: 'groups' }
        ]
    },
    {
        icon: 'settings',
        title: 'Настройки',
        src: '/settings/crm',
        access: settingStore.moduleName,
        submenu: [
            {
                title: 'Настройки CRM',
                src: '/settings/crm',
                access: settingStore.moduleName
            },
            {
                title: 'Экспорт / Фиды',
                src: '/ads/price',
                accessPermission: ACCESS_COMPANY,
                access: exportStore.moduleName
            }
        ]
    }
];
